export const ScheduleProgramsArray = [
    {
        id: 1, 
        subtitle: 'FALL',
        logo: '/images/icons/quest_calendar.svg',
        listItems: [
            {
                childArray: [
                    '<strong>SEPTEMBER 12 - OCTOBER 31<strong> <br>',
                    `  <br>  TUESDAY | THURSDAY<br>
                        5:30 - 6:30 pm <br>
                        <br> PICKERING: <br> Pine Ridge Secondary School <br>2155 Liverpool Road
                    `
                ]
            }  
        ]
    },
    {
        id: 2, 
        subtitle: 'WINTER',
        logo: '/images/icons/quest_calendar.svg',
        listItems: [
            {
                childArray: [
                    '<strong>NOVEMBER 1 - MARCH 31<strong>',
                    `     <br>
                        TUESDAY<br>
                        7:30 pm - 8:30pm <br>
                        <br> WHITBY: <br> Abilities Centre <br>55 Gordon St, L1N 0J2 <br>
                        <br>
                        WEDNESDAY<br>
                        6:15 pm - 7:15 pm <br>
                        <br> PICKERING: <br> Pickering Soccer Centre<br> 1975 Clements Rd <br>
                        <br>
                        SATURDAY<br>
                        10:30 am - 11:30 pm <br>
                        <br> PICKERING: <br> Pickering Soccer Centre <br>1975 Clements Rd <br>
                        <br>
                        SUNDAY<br>
                        3:00 pm - 4:00 pm <br>
                        <br> WHITBY: <br> Abilities Centre<br> 55 Gordon St 
                    `
                ]
            }  
        ] 
    }
]

export const SCScheduleProgramsArray = [
    {
        id: 1, 
        subtitle: 'QUEST GROUP A',
        logo: '/images/icons/quest_calendar.svg',
        listItems: [
            {
                childArray: [
                    `  MONDAY | THURSDAY <br>
                        4:30 pm - 6:00 pm`,
                    `
                    <br>  SATURDAY<br>
                        1:00 pm - 2:30 pm <br>
                        <br> AJAX:<br> 377 Mackenzie Avenue Unit 12-14
                    `,
                    `
                    <br>   MONDAY | THURSDAY<br>
                        6:00 pm - 7:30 pm <br>
                        <br> AJAX:<br> 377 Mackenzie Avenue Unit 12-14
                    `
                ]
            }  
        ]
    },
    {
        id: 2, 
        subtitle: 'STRENGTH & CONDITIONING FOR ATHLETES',
        logo: '/images/icons/quest_calendar.svg',
        listItems: [
            {
                childArray: [
                    `MONDAY | THURSDAY <br>
                    7:30 pm -9:00 pm  <br>
                    <br> AJAX: <br> 377 Mackenzie Avenue Unit 12-14 <br>`,
                    ` <br>TUESDAY | WEDNESDAY | FRIDAY
                    4:00 pm - 5:30 pm <br>
                    5:30 pm - 7:00 pm <br>
                    7:00 pm - 8:30 pm <br>
                    <br>AJAX: <br> 377 Mackenzie Avenue Unit 12-14 <br>`,
                    ` <br>SATURDAY<br>
                    2:30 pm - 5:00 pm <br>
                    <br>AJAX: <br> 377 Mackenzie Avenue Unit 12-14`
                ]
            }  
        ]
    },
    {
        id: 3, 
        subtitle: 'OLY KIDS WLC',
        logo: '/images/icons/quest_calendar.svg',
        listItems: [
            {
                childArray: [
                    `
                        SATURDAY<br>
                        10:00 am - 12:00pm <br>
                        <br> AJAX: <br> 377 Mackenzie Avenue Unit 12-14 <br>
                    `,
                    `
                    <br>  SUNDAY<br>
                    1:00pm - 3:00pm  <br>
                    <br> AJAX: <br> 377 Mackenzie Avenue Unit 12-14
                    `
                ]
            }  
        ]
    }
]

export const ElementaryScheduleProgramsArray = [ 
    {
        id: 1, 
        subtitle: 'FALL MEMBERSHIP',
        logo: '/images/icons/quest_calendar.svg',
        listItems: [
            {
                childArray: [
                    `<strong>SEPTEMBER 12 - OCTOBER 31<strong> <br>` ,
                    `<br>TUESDAY | THURSDAY<br>
                    6:00 pm - 7:00 pm <br>
                    <br> PICKERING: <br> Pine Ridge Secondary School <br>2155 Liverpool Road North`
                ]
            }  
        ]
    },
    {
        id: 2, 
        subtitle: 'WINTER MEMBERSHIP',
        logo: '/images/icons/quest_calendar.svg',
        listItems: [
            {
                childArray: [
                    `<strong>NOVEMBER 1 - MARCH 31<strong>` ,
                    ` <br>
                    MONDAY
                    <br>6:15 pm - 7:45 pm <br><br>
                    PICKERING: <br> Pickering Soccer Centre<br> 1975 Clements Rd`,
                    `
                    <br>
                    THURSDAY<br>
                    4:30 pm - 6:00 pm <br><br>
                    PICKERING: <br> Pickering Soccer Centre<br> 1975 Clements Rd
                    `,
                    `
                    <br>
                    SUNDAY<br>
                    3:30 pm - 5:00 pm <br><br>
                    WHITBY: <br> Abilities Centre<br> 55 Gordon St
                    `,
                    ' <br> Athletes will be scheduled two of the three available days'
                ]
            }  
        ]
    }, 
    {
        id: 3, 
        subtitle: 'SPRING MEMBERSHIP',
        logo: '/images/icons/quest_calendar.svg',
        listItems: [
            {
                childArray: [
                    `<strong>April 1st - June 30th</strong><br>
                    <br> TUESDAY | THURSDAY<br>
                    5:00 pm - 6:15 pm <br>
                    <br>PICKERING: <br> Pine Ridge Secondary School<br> 2155 Liverpool Road North` 
                ]
            }  
        ]
    }, 
    {
        id: 4, 
        subtitle: 'SUMMER MEMBERSHIP',
        logo: '/images/icons/quest_calendar.svg',
        listItems: [
            {
                childArray: [
                    `<strong>July 1st - Aug 15th</strong><br>
                    <br>TUESDAY | THURSDAY 
                    <br> 5:00 pm - 6:15 pm <br>
                    <br> PICKERING: <br> Pine Ridge Secondary School<br> 2155 Liverpool Road North` 
                ]
            }  
        ]
    },
];

export const HighSchoolScheduleProgramsArray = [ 
    {
        id: 1, 
        subtitle: 'JUNIOR SCHOOL',
        logo: '/images/icons/quest_calendar.svg',
        listItems: [
            {
                childArray: [ 
                    `<strong>Monday</strong><br> 4:30 - 6:00 - New Location<br> 6:15 - 7:45 - Durham<br><br>`,
                    `<strong>Tuesday</strong><br> 6:00 - 6:45 - Durham<br> 6:45 - 7:30 - Durham<br><br>`,
                    `<strong>Wednesday</strong><br> 4:30 - 6:30 - New Location<br><br>`,
                    `<strong>Thursday</strong><br> 4:30 - 6:00 - New Location<br><br>`, 
                    `<strong>Saturday</strong><br> 11:00 - 12:00 - Durham<br> 12:30 - 1:30 - Durham<br> 12:30 - 2:00 - New Location<br>`,
                ]
            }  
        ]
    },
    {
        id: 2, 
        subtitle: 'MIDDLE SCHOOL',
        logo: '/images/icons/quest_calendar.svg',
        listItems: [
            {
                childArray: [ 
                    `<strong>Monday</strong><br> 4:30 - 6:00 - New Location <br> 6:15 - 7:45 - Durham<br><br>`,
                    `<strong>Tuesday</strong><br> 6:00 - 6:45 - Durham<br> 6:45 - 7:30 - Durham<br> 7:30 - 9:00 - Durham<br><br>`,
                    `<strong>Wednesday</strong><br> 4:30 - 6:30 - New Location<br><br>`,
                    `<strong>Thursday</strong><br> 4:30 - 6:00 - New Location<br> 5:30 - 7:00 - York University<br><br>`,
                    `<strong>Friday</strong><br> 7:30 - 9:00 - Durham<br><br>`,
                    `<strong>Saturday</strong><br> 11:00 - 12:00 - Durham<br> 12:30 - 1:30 - Durham<br> 12:30 - 2:00 - New Location<br><br>`,
                    `<strong>Sunday</strong><br> 7:30 - 9:00 - Durham<br>`,
                ]
            }  
        ]
    }, 
    {
        id: 3, 
        subtitle: 'HIGH SCHOOL',
        logo: '/images/icons/quest_calendar.svg',
        listItems: [
            {
                childArray: [ 
                    `<strong>Monday</strong><br> 4:30 - 6:00 - New Location<br> 6:15 - 7:45 - Durham<br> 6:00 - 7:30 - York University<br><br>`,
                    `<strong>Tuesday</strong><br> 6:00 - 7:30 - Durham<br> 7:30 - 9:00 - Durham<br> 5:00 - 6:30 - York University<br><br>`,
                    `<strong>Wednesday</strong><br> 4:30 - 6:30 - New Location<br> 6:15 - 8:00 - Durham<br> 5:30 - 7:30 - York University<br><br>`,
                    `<strong>Thursday</strong><br> 4:30 - 6:00 - New Location<br> 5:30 - 7:00 - York University<br><br>`,
                    `<strong>Friday</strong><br> 7:30 - 9:00 - Durham<br><br>`,
                    `<strong>Saturday</strong><br> 12:30 - 2:00 - New Location<br> 10:00 - 12:00 - Durham<br>`,
                ]
            }  
        ]
    }
];