import React from 'react';
import './ProgramsSchedulingWrapper.css'; 
import Accomplishments from '../Accomplishments/Accomplishments';

const ProgramsSchedulingWrapper = ({schedule_heading, schedule_paragraph, sectionBgImage, slider_heading, slider_array}) => {
  return (
    <div className='schedule_programs_wrapper'>
      <div className='schedule_heading'><h1>{schedule_heading}</h1></div> 
 
      <Accomplishments backgroundImage={sectionBgImage} schedule_paragraph={schedule_paragraph} heading={slider_heading} defaultArray={slider_array} />
    </div>
  )
}

export default ProgramsSchedulingWrapper;