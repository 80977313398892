import React, { Fragment } from 'react'
import RegularHero from '../components/Hero-Section/RegularHero';
import Associations from '../components/Associations Slider/Associations';
import ProgramsSchedulingWrapper from '../components/Programs Scheduling/ProgramsSchedulingWrapper';
import { HighSchoolScheduleProgramsArray, SCScheduleProgramsArray, ScheduleProgramsArray } from '../components/Constants/SchedulingPrograms';

const Schedule = () => {
  return (
    <Fragment> 
      <RegularHero
        backgroundImage= {'/images/creatives/schedule-hero.png'} 
        startHighlightedText={true}
        highlightedText={'QUEST SPORTS SCHEDULE'}
        breakLine={true}  
      />
      <Associations /> 
      <ProgramsSchedulingWrapper schedule_paragraph={`Training programs for athletes will be scheduled according to each athlete's specific event, ensuring tailored preparation and targeted development for optimal performance.`} sectionBgImage={'/images/creatives/high_school_schedule.png'} slider_heading='TRACK SCHEDULE' slider_array={HighSchoolScheduleProgramsArray}/>
      <ProgramsSchedulingWrapper sectionBgImage={'/images/creatives/s_c_schedule.png'} slider_heading='STRENGTH & CONDITIONING SCHEDULE' slider_array={SCScheduleProgramsArray}/> 
      <ProgramsSchedulingWrapper sectionBgImage={'/images/creatives/speed_training_schedule.png'} slider_heading='SPEED TRAINING SCHEDULE' slider_array={ScheduleProgramsArray}/> 
    </Fragment>
  )
}

export default Schedule;