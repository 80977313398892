import React, {useState, useRef} from 'react';  
import './Accomplishments.css';
import { AccomplishmentsArray } from '../Constants/QuestAccomplishments';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Navigation, Pagination } from 'swiper/core';

SwiperCore.use([Autoplay, Navigation, Pagination]);

const Accomplishments = ({backgroundImage, schedule_paragraph, defaultArray=AccomplishmentsArray, heading='QUEST SPORTS ACCOMPLISHMENTS'}) => { 
  const [hoveredCardId, setHoveredCardId] = useState(null); 
  console.log(defaultArray?.length === 2)
  let noOfSlidesToRender = defaultArray?.length === 2;
  return (
    <div className="slider-wrapper-accomplishments" style={{backgroundImage: `url(${backgroundImage})`}}>
        <div className="container">
            <div className='accomplishments_info text-center mb-5'>
                <h1>{heading}</h1>
                {schedule_paragraph && <p>{schedule_paragraph}</p>}
            </div>
            <div className='text-center'> 
                <Swiper 
                    spaceBetween={30}
                    slidesPerView={noOfSlidesToRender ? 2 : 3}
                    loop={defaultArray?.length === 2 || defaultArray?.length === 3 || defaultArray?.length === 4 ? false : true}
                    autoplay={{ delay: 1000 }}
                    speed={1000} 
                    navigation={false}
                    pagination={{ clickable: true }}
                    className='accomplishments_slider'
                    breakpoints={{
                        280: {
                        slidesPerView: 1,
                        },
                        340: {
                        slidesPerView: 1,
                        },
                        440: {
                        slidesPerView: 1,
                        },
                        540: {
                        slidesPerView: 1,
                        },
                        600: {
                        slidesPerView: 1,
                        },
                        640: {
                            slidesPerView: 2,
                        },
                        768: {
                            slidesPerView: noOfSlidesToRender ? 2 : 3,
                        },
                        1024: {
                            slidesPerView: noOfSlidesToRender ? 2 : 3,
                        },
                    }}
                >
                    {defaultArray.map((card) => (
                        <SwiperSlide className='mb-5 mb-lg-0' key={card.id}>
                            <div className={`card mb-5 p-3 ${card.id === hoveredCardId ? "" : ""}`}
                                onMouseEnter={() => setHoveredCardId(card.id)}
                                onMouseLeave={() => setHoveredCardId(null)}
                            >
                                <div className='row text-center'>
                                    <div className='col-12 pt-3'> 
                                        <div className='accomplishments_info'>
                                            <img src={card.logo} className='img-fluid accomplishment_logo' />
                                            <h1 className='year'>{card.year}</h1> 
                                            <h2 className='year_subtitle'>{card.subtitle}</h2>
                                            {card.listItems && card.listItems.map((item) =>  {
                                                return ( 
                                                    <div className='mb-3'>
                                                        {item.childArray.map((element) => (
                                                            <p className='list-item-accomp'>
                                                                <div dangerouslySetInnerHTML={{ __html: element }} />
                                                            </p>
                                                        ))}
                                                    </div> 
                                                )
                                            })}
                                        </div> 
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper> 
            </div>
        </div>
    </div>
  )
}

export default Accomplishments;